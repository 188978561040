<template>
  <v-alert v-if="alertData.status" :type="alertData.status">
    {{ alertData.msg }}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    alertData: {
      type: Object,
      required: true
    }
  }
}
</script>
